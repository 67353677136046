/* src/components/LoadingSpinner/LoadingSpinner.module.css */
/* Contenedor principal del spinner */
.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* El spinner en sí */
.spinner {
  border-radius: 50%;
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-top-color: currentColor;
  animation: spin 0.8s linear infinite;
}

/* Tamaños disponibles */
.extraSmall .spinner {
  width: 16px;
  height: 16px;
  border-width: 2px;
}

.small .spinner {
  width: 20px;
  height: 20px;
  border-width: 2px;
}

.medium .spinner {
  width: 36px;
  height: 36px;
  border-width: 3px;
}

.large .spinner {
  width: 60px;
  height: 60px;
  border-width: 4px;
}

/* Colores disponibles */
.primary {
  color: #8b5cf6; /* Morado */
}

.secondary {
  color: #10b981; /* Verde */
}

.warning {
  color: #f59e0b; /* Ámbar */
}

.danger {
  color: #ef4444; /* Rojo */
}

.light {
  color: #e5e7eb; /* Gris claro */
}

.white {
  color: #ffffff; /* Blanco, para usar en botones con fondo oscuro */
}

/* Animación de giro */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Adaptar spinner para botones */
button .spinnerContainer {
  display: inline-flex;
}

button.primary .spinner {
  border-color: rgba(255, 255, 255, 0.2);
  border-top-color: #ffffff;
}