:root {
  --header-height: 70px;
  --toast-z-index: 9999;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
}

.appContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.mainContent {
  flex: 1;
  margin-top: var(--header-height);
  min-height: calc(100vh - var(--header-height));
  background-color: var(--background-color);
  transition: filter 0.3s ease;
  width: 100%;
  position: relative;
}

.blurBackground {
  filter: blur(3px);
  pointer-events: none;
  user-select: none;
}

.Toastify__toast-container {
  z-index: var(--toast-z-index);
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.Toastify__toast {
  position: relative;
  z-index: var(--toast-z-index);
  min-height: unset;
  padding: 1rem;
  border-radius: 12px;
  box-shadow: var(--box-shadow);
  margin-bottom: 1rem;
}

.Toastify__toast--success {
  background-color: var(--success-color);
}

.Toastify__toast--error {
  background-color: var(--error-color);
}

.Toastify__toast--warning {
  background-color: var(--warning-color);
}

.Toastify__toast--info {
  background-color: var(--primary-color);
}

.Toastify__toast-body {
  font-family: inherit;
  font-size: 0.9rem;
  padding: 0;
  margin: 0;
}

.Toastify__close-button {
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.Toastify__close-button:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  :root {
    --header-height: 60px;
  }

  .Toastify__toast-container {
    width: calc(100% - 2rem);
    padding: 0;
    left: 1rem;
    right: 1rem;
  }

  .Toastify__toast {
    margin-bottom: 0.5rem;
  }
}