/* src/components/AddBalance.css */

/* Superposición de la Modal */
.modal-addBalance-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000; /* Valor alto para asegurar que esté por encima de todo */
}

/* Contenido de la Modal */
.modal-addBalance-content {
  width: 90%;
  max-width: 500px;
  background-color: var(--background-color, #1a1a20);
  border-radius: var(--border-radius, 8px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  animation: modalFadeIn 0.3s ease;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Encabezado de la Modal */
.modal-addBalance-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid var(--border-color, #2d2d2d);
}

.modal-addBalance-header h3 {
  margin: 0;
  font-size: 1.25rem;
  color: var(--text-color, #f2f2f2);
}

.modal-addBalance-close-button {
  background: none;
  border: none;
  color: var(--text-secondary, rgba(242, 242, 242, 0.7));
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0;
  line-height: 1;
  transition: color 0.2s ease;
}

.modal-addBalance-close-button:hover {
  color: var(--text-color, #f2f2f2);
}

/* Campo de Entrada */
.modal-addBalance-input {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border: 2px solid rgba(128, 128, 128, 0.2);
  border-radius: 12px;
  font-size: 1.1rem;
  background-color: var(--background-color);
  color: var(--text-color);
  transition: all 0.3s ease;
}

.modal-addBalance-input:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(52, 131, 250, 0.1);
  outline: none;
}

/* Opciones de pago unificadas */
.modal-addBalance-payment-options {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  margin-bottom: 20px;
}

.modal-addBalance-payment-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border-radius: 8px;
  background-color: var(--background-color-secondary, #2d2d2d);
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.modal-addBalance-payment-option:hover:not(:disabled) {
  border-color: var(--primary-color, #3483fa);
  transform: translateY(-2px);
}

.modal-addBalance-payment-option.active {
  border-color: var(--primary-color, #3483fa);
  background-color: rgba(52, 131, 250, 0.1);
}

.modal-addBalance-payment-option:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.modal-addBalance-payment-option-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.modal-addBalance-payment-option-currency {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--text-color, #f2f2f2);
}

.modal-addBalance-payment-option-provider {
  display: flex;
  align-items: center;
  gap: 8px;
}

.modal-addBalance-payment-option-provider img {
  height: 30px;
  width: auto;
  object-fit: contain;
  max-width: 100px;
  background-color: transparent;
  border-radius: 4px;
}

.modal-addBalance-payment-option-provider span {
  font-size: 0.9rem;
  color: var(--text-secondary, rgba(242, 242, 242, 0.7));
}

/* Estilos para modo claro */
:root[data-theme="light"] .modal-addBalance-payment-option {
  background-color: #f5f5f5;
  border-color: #e0e0e0;
}

:root[data-theme="light"] .modal-addBalance-payment-option.active {
  background-color: rgba(52, 131, 250, 0.1);
  border-color: #3483fa;
}

:root[data-theme="light"] .modal-addBalance-payment-option-currency {
  color: #333;
}

:root[data-theme="light"] .modal-addBalance-payment-option-provider span {
  color: #666;
}

/* Responsive */
@media (min-width: 768px) {
  .modal-addBalance-payment-options {
    flex-direction: row;
  }
  
  .modal-addBalance-payment-option {
    flex: 1;
  }
}

/* Sección de Términos y Condiciones */
.modal-addBalance-terms-section {
  margin: 1.5rem 0;
}

.modal-addBalance-terms-scrollable {
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 12px;
  max-height: 150px;
  overflow-y: auto;
  background-color: var(--background-color-secondary);
  border: 2px solid rgba(128, 128, 128, 0.1);
}

.modal-addBalance-checkbox-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 1rem 0;
}

.modal-addBalance-checkbox-container input[type="checkbox"] {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.modal-addBalance-checkbox-container label {
  color: var(--text-color);
  font-size: 0.95rem;
}

/* Confirmación de Pago */
.modal-addBalance-confirmation {
  background-color: var(--background-color-secondary);
  padding: 1.5rem;
  border-radius: 12px;
  margin: 1rem 0;
}

.modal-addBalance-confirmation p {
  margin: 0.75rem 0;
  font-size: 1.1rem;
  line-height: 1.4;
}

/* Pie de la Modal */
.modal-addBalance-footer {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 2rem;
}

.modal-addBalance-button {
  flex: 1;
  padding: 1rem;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
}

.modal-addBalance-button.primary {
  background-color: #28a745;
  color: white;
}

.modal-addBalance-button:not(.primary) {
  background-color: #6c757d;
  color: white;
}

.modal-addBalance-button:not(.primary):hover:not(:disabled) {
  background-color: #5a6268;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.modal-addBalance-button.primary:hover:not(:disabled) {
  background-color: #218838;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(40, 167, 69, 0.2);
}

/* Loader */
.modal-addBalance-loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  display: inline-block;
  margin-right: 8px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Dark Mode Styles */
.dark .modal-addBalance-content {
  background-color: #1a1a1a;
}

.dark .modal-addBalance-input {
  background-color: #2d2d2d;
  border-color: #404040;
}

.dark .modal-addBalance-terms-scrollable {
  background-color: #2d2d2d;
  border-color: #404040;
}

.dark .modal-addBalance-confirmation {
  background-color: #2d2d2d;
}

.dark .modal-addBalance-checkbox-container label {
  color: #f0f0f0;
}

/* Responsive */
@media (max-width: 480px) {
  .modal-addBalance-content {
    padding: 1.5rem;
    width: 95%;
  }

  .modal-addBalance-header h3 {
    font-size: 1.5rem;
  }

  .modal-addBalance-input,
  .modal-addBalance-payment-button,
  .modal-addBalance-button {
    padding: 0.875rem;
    font-size: 1rem;
  }

  .modal-addBalance-payment-button.mercado-pago {
    margin-top: 0.5rem;
  }
}


.modal-addBalance-button.primary:disabled {
  background-color: #6c757d; /* Cambia el color de fondo cuando está deshabilitado */
  cursor: not-allowed; /* Cambia el cursor a "no permitido" */
  opacity: 0.6; /* Reduce la opacidad del botón */
}

.modal-addBalance-button.primary:disabled:hover::after {
  content: attr(title); /* Muestra el mensaje del atributo "title" */
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
  white-space: nowrap;
}

/* Modal de Balance */
.modal-addBalance-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000; /* Valor alto para asegurar que esté por encima de todo */
}

.modal-addBalance-content {
  width: 90%;
  max-width: 500px;
  background-color: var(--background-color, #1a1a20);
  border-radius: var(--border-radius, 8px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  animation: modalFadeIn 0.3s ease;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-addBalance-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid var(--border-color, #2d2d2d);
}

.modal-addBalance-header h3 {
  margin: 0;
  font-size: 1.25rem;
  color: var(--text-color, #f2f2f2);
}

.modal-addBalance-close-button {
  background: none;
  border: none;
  color: var(--text-secondary, rgba(242, 242, 242, 0.7));
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0;
  line-height: 1;
  transition: color 0.2s ease;
}

.modal-addBalance-close-button:hover {
  color: var(--text-color, #f2f2f2);
}

.modal-addBalance-body {
  padding: 1rem;
}

.modal-addBalance-form-group {
  margin-bottom: 1rem;
}

.modal-addBalance-form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: var(--text-color, #f2f2f2);
}

.modal-addBalance-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--border-color, #2d2d2d);
  border-radius: var(--border-radius, 8px);
  background-color: var(--background-elevated, #2f2f38);
  color: var(--text-color, #f2f2f2);
  font-size: 1rem;
  transition: border-color 0.2s ease;
}

.modal-addBalance-input:focus {
  outline: none;
  border-color: var(--primary-color, #8b5cf6);
  box-shadow: 0 0 0 2px var(--primary-alpha, rgba(139, 92, 246, 0.2));
}

.modal-addBalance-summary {
  margin: 1rem 0;
  padding: 1rem;
  background-color: var(--background-elevated, #2f2f38);
  border-radius: var(--border-radius, 8px);
}

.modal-addBalance-summary-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  color: var(--text-secondary, rgba(242, 242, 242, 0.7));
}

.modal-addBalance-summary-total {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid var(--border-color, #2d2d2d);
  font-weight: 600;
  color: var(--text-color, #f2f2f2);
}

.modal-addBalance-checkbox-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.modal-addBalance-checkbox-container input[type="checkbox"] {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.modal-addBalance-checkbox-container label {
  margin-bottom: 0;
  cursor: pointer;
}

.modal-addBalance-checkbox-container a {
  color: var(--primary-color, #8b5cf6);
  text-decoration: none;
}

.modal-addBalance-checkbox-container a:hover {
  text-decoration: underline;
}

.modal-addBalance-alert-danger {
  padding: 0.75rem;
  margin-bottom: 1rem;
  background-color: rgba(220, 38, 38, 0.1);
  border: 1px solid rgba(220, 38, 38, 0.3);
  border-radius: var(--border-radius, 8px);
  color: #ef4444;
}

.modal-addBalance-footer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1rem;
  border-top: 1px solid var(--border-color, #2d2d2d);
}

.modal-addBalance-button-primary,
.modal-addBalance-button-secondary {
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius, 8px);
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}

.modal-addBalance-button-primary {
  background-color: var(--primary-color, #8b5cf6);
  color: white;
}

.modal-addBalance-button-primary:hover:not(:disabled) {
  background-color: var(--primary-hover, #7c3aed);
}

.modal-addBalance-button-secondary {
  background-color: transparent;
  border: 1px solid var(--border-color, #2d2d2d);
  color: var(--text-color, #f2f2f2);
}

.modal-addBalance-button-secondary:hover {
  background-color: var(--background-hover, rgba(47, 47, 56, 0.5));
}

.modal-addBalance-button-primary:disabled {
  background-color: var(--background-elevated, #2f2f38);
  color: var(--text-tertiary, rgba(242, 242, 242, 0.5));
  cursor: not-allowed;
}

/* Estilos para modo claro */
:root[data-theme="light"] .modal-addBalance-content {
  background-color: var(--background-color, #ffffff);
  color: var(--text-color, #333333);
}

:root[data-theme="light"] .modal-addBalance-header h3 {
  color: var(--text-color, #333333);
}

:root[data-theme="light"] .modal-addBalance-close-button {
  color: var(--text-secondary, #666666);
}

:root[data-theme="light"] .modal-addBalance-close-button:hover {
  color: var(--text-color, #333333);
}

:root[data-theme="light"] .modal-addBalance-input {
  background-color: var(--background-color, #ffffff);
  color: var(--text-color, #333333);
  border-color: var(--border-color, #e2e8f0);
}

:root[data-theme="light"] .modal-addBalance-summary {
  background-color: var(--background-color-secondary, #f5f5f5);
}

:root[data-theme="light"] .modal-addBalance-summary-item {
  color: var(--text-secondary, #666666);
}

:root[data-theme="light"] .modal-addBalance-summary-total {
  color: var(--text-color, #333333);
}

:root[data-theme="light"] .modal-addBalance-button-secondary {
  color: var(--text-color, #333333);
}

/* Responsive */
@media (max-width: 480px) {
  .modal-addBalance-content {
    width: 95%;
    padding: 1rem;
  }
  
  .modal-addBalance-header h3 {
    font-size: 1.1rem;
  }
  
  .modal-addBalance-footer {
    flex-direction: column-reverse;
    gap: 0.5rem;
  }
  
  .modal-addBalance-button-primary,
  .modal-addBalance-button-secondary {
    width: 100%;
    padding: 0.75rem;
  }
}

.modal-addBalance-payment-methods {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 0.5rem;
}

.modal-addBalance-payment-method {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  border-radius: var(--border-radius, 8px);
  background-color: var(--background-elevated, #2f2f38);
  border: 1px solid var(--border-color, #2d2d2d);
  color: var(--text-color, #f2f2f2);
  cursor: pointer;
  transition: all 0.2s ease;
}

.modal-addBalance-payment-method:hover:not(:disabled) {
  background-color: var(--background-hover, rgba(47, 47, 56, 0.5));
  border-color: var(--primary-color, #8b5cf6);
}

.modal-addBalance-payment-method.active {
  border-color: var(--primary-color, #8b5cf6);
  background-color: var(--primary-alpha, rgba(139, 92, 246, 0.2));
}

.modal-addBalance-payment-method:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.modal-addBalance-payment-logo {
  height: 24px;
  width: auto;
  object-fit: contain;
}

/* Selector de moneda */
.modal-addBalance-currency-selector {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.modal-addBalance-currency-option {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f8f9fa;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  text-align: center;
}

.modal-addBalance-currency-option:hover:not(:disabled) {
  background-color: #e9ecef;
  border-color: #ced4da;
}

.modal-addBalance-currency-option.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.modal-addBalance-currency-option:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Tema oscuro para selector de moneda */
:root[data-theme="dark"] .modal-addBalance-currency-option {
  background-color: #2d3748;
  border-color: #4a5568;
  color: #e2e8f0;
}

:root[data-theme="dark"] .modal-addBalance-currency-option:hover:not(:disabled) {
  background-color: #4a5568;
  border-color: #718096;
}

:root[data-theme="dark"] .modal-addBalance-currency-option.active {
  background-color: #3182ce;
  border-color: #4299e1;
}

/* Responsive para selector de moneda */
@media (max-width: 480px) {
  .modal-addBalance-currency-selector {
    flex-direction: column;
  }
}

/* Mensaje informativo */
.modal-addBalance-info-message {
  margin: 15px 0;
  padding: 10px 15px;
  background-color: #e8f4fd;
  border-left: 4px solid #3498db;
  border-radius: 4px;
}

.modal-addBalance-info-message p {
  margin: 0;
  font-size: 0.9rem;
  color: #2c3e50;
}

/* Tema oscuro para el mensaje informativo */
:root[data-theme="dark"] .modal-addBalance-info-message {
  background-color: #1a2733;
  border-left-color: #3182ce;
}

:root[data-theme="dark"] .modal-addBalance-info-message p {
  color: #e2e8f0;
}

/* Estilos para el logo de texto de MercadoPago */
.mercadopago-text-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #009EE3;
  border-radius: 4px;
  padding: 5px 10px;
  min-width: 120px;
  min-height: 30px;
}

.mercadopago-text {
  color: white;
  font-weight: bold;
  font-size: 14px;
  font-family: Arial, sans-serif;
  text-align: center;
  white-space: nowrap;
}