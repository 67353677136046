:root {
  /* Variables de z-index globales */
  --z-negative: -1;
  --z-base: 1;
  --z-elevate: 2;
  --z-dropdown: 10;
  --z-sticky: 15;
  --z-content: 20;
  --z-perfil-avatar: 30;
  --z-header: 50;
  --z-overlay: 100;
  --z-modal-backdrop: 99990;
  --z-modal-overlay: 99999;
  --z-modal-content: 100000;
  --z-tooltip: 100010;
  
  /* Otras variables... */
}

/* Fix para modales */
body.modal-open {
  overflow: hidden;
}

/* Clase para asegurar que los elementos se muestren encima de los demás */
.z-top {
  z-index: var(--z-modal-content);
  position: relative;
}

/* Clase para resetear el z-index */
.z-reset {
  z-index: var(--z-base) !important;
}

/* Resto del CSS... */
