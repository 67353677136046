/* src/components/darkMode/ThemeToggle.module.css */

.themeToggle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 
    background-color 0.3s ease, 
    color 0.3s ease, 
    transform 0.2s ease, /* Reducido para una transición más rápida */
    box-shadow 0.3s ease;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Estilos para el tema claro */
.light {
  background-color: #8A2BE2; /* Violeta */
  color: #ffffff;
}

/* Estilos para el tema oscuro */
.dark {
  background-color: #9400D3; /* Violeta oscuro */
  color: #ffffff;
}

.themeToggle:hover {
  transform: scale(1.05); /* Reducción del escalado para evitar exageraciones */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Eliminamos la transición de color en el estado activo para evitar cambios abruptos */
.themeToggle:active {
  transform: scale(1); /* Evitamos el escalado al hacer clic */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Restablecemos la sombra */
}

/* Mejoramos el estilo de enfoque para mayor accesibilidad sin alterar el color */
.themeToggle:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(138, 43, 226, 0.5); /* Anillo de enfoque suave violeta */
}

@media (prefers-reduced-motion: reduce) {
  .themeToggle {
    transition: none;
  }
}
