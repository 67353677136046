/* global.css */
/* ===============================
   Reset & Box Sizing
=============================== */
*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* ===============================
   Base Typography & Layout
=============================== */
html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  line-height: 1.5;
  background-color: var(--background-color);
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

/* ===============================
   Clases para temas y personalización
=============================== */
/* Modo oscuro */
:root[data-theme="dark"] {
  /* Las variables de color para modo oscuro se definen en variables.css */
  --current-bg-rgb: var(--dark-bg-rgb);
  --current-element-rgb: var(--dark-element-rgb);
}

/* Clases de Personalización - Transparencia */
.transparent-component {
  background-color: rgba(var(--current-bg-rgb), var(--component-opacity)) !important;
  backdrop-filter: blur(var(--bg-blur));
  transition: background-color var(--transition-speed),
              backdrop-filter var(--transition-speed);
  position: relative;
}

.transparent-element {
  background-color: rgba(var(--current-element-rgb), var(--element-opacity)) !important;
  backdrop-filter: blur(var(--bg-blur));
  transition: background-color var(--transition-speed),
              backdrop-filter var(--transition-speed);
}

.custom-background {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-image: var(--background-image);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
}

.custom-overlay {
  position: absolute;
  inset: 0;
  background-color: var(--overlay-color);
  opacity: var(--app-overlay-opacity);
  z-index: 1;
}

/* Aplicación dinámica de transparencia */
:root[data-custom-enabled="true"] {
  --component-opacity: var(--app-transparency-value);
  --element-opacity: var(--app-element-opacity);
  --bg-blur: var(--app-blur-value);
  --overlay-opacity: var(--app-overlay-opacity);
}

/* Ajuste para elementos con data-custom="true" */
[data-custom="true"] {
  background-color: rgba(var(--current-bg-rgb), var(--component-opacity)) !important;
  backdrop-filter: blur(var(--bg-blur)) !important;
  position: relative;
  z-index: 2;
}

[data-custom="true"] > * {
  position: relative;
  z-index: 2;
}

/* ===============================
   Utilidades y clases de ayuda
=============================== */
html, body, #root {
  height: 100%;
}

.hidden {
  display: none !important;
}

.flex {
  display: flex !important;
}

.text-center {
  text-align: center !important;
}

/* Responsividad */
@media (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

/* Animaciones */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity var(--transition-speed);
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity var(--transition-speed);
}

/* ===============================
   Soporte para casos especiales y navegadores antiguos
=============================== */

/* Soporte para navegadores que no soportan backdrop-filter */
@supports not (backdrop-filter: blur(1px)) {
  [data-custom="true"],
  .transparent-component,
  .transparent-element {
    backdrop-filter: none !important;
    background-color: rgba(var(--current-bg-rgb), 0.97) !important;
  }

  :root[data-theme="dark"] [data-custom="true"],
  :root[data-theme="dark"] .transparent-component,
  :root[data-theme="dark"] .transparent-element {
    background-color: rgba(var(--dark-bg-rgb), 0.97) !important;
  }
}

/* Mejoras para visibilidad de texto en modo oscuro con transparencia */
:root[data-theme="dark"] [data-custom="true"] h1,
:root[data-theme="dark"] [data-custom="true"] h2,
:root[data-theme="dark"] [data-custom="true"] h3,
:root[data-theme="dark"] [data-custom="true"] h4,
:root[data-theme="dark"] [data-custom="true"] h5,
:root[data-theme="dark"] [data-custom="true"] h6 {
  color: var(--text-color);
}

:root[data-theme="dark"] [data-custom="true"] p,
:root[data-theme="dark"] [data-custom="true"] span,
:root[data-theme="dark"] [data-custom="true"] div:not([data-custom="true"]) {
  color: var(--text-secondary);
}

/* Ajustes específicos para ServiceCard - mantener compatibilidad */
.serviceCard {
  background-color: var(--card-background);
}

.serviceCard[data-custom="true"] {
  background-color: rgba(var(--current-bg-rgb), var(--component-opacity)) !important;
  backdrop-filter: blur(var(--bg-blur)) !important;
  border-color: rgba(var(--current-element-rgb), 0.2) !important;
}

.serviceContent[data-custom="true"] {
  background-color: rgba(var(--current-element-rgb), var(--element-opacity)) !important;
  backdrop-filter: blur(var(--bg-blur)) !important;
}

/* Ajustes específicos para ServiceCard en modo oscuro */
:root[data-theme="dark"] .serviceCard[data-custom="true"] {
  background-color: rgba(var(--dark-bg-rgb), var(--component-opacity)) !important;
  backdrop-filter: blur(var(--bg-blur)) !important;
  border-color: rgba(var(--dark-element-rgb), 0.2) !important;
}

:root[data-theme="dark"] .serviceContent[data-custom="true"] {
  background-color: rgba(var(--dark-element-rgb), var(--element-opacity)) !important;
  backdrop-filter: blur(var(--bg-blur)) !important;
}