.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
}

.container {
  width: 100%;
  max-width: 400px;
  height: 100%;
  background-color: var(--card-background, white);
  box-shadow: -8px 0 24px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
}

.header {
  padding: 1.5rem;
  border-bottom: 1px solid var(--border-color, #e5e7eb);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to right, rgba(139, 92, 246, 0.1), transparent);
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.headerIcon {
  font-size: 1.5rem;
  color: var(--primary-color, #8b5cf6);
}

.header h2 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-color, #374151);
}

.closeButton {
  background: transparent;
  border: none;
  color: var(--text-muted, #6b7280);
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.closeButton:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: var(--text-color, #374151);
}

.content {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
}

.loadingState,
.errorState,
.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  color: var(--text-muted, #6b7280);
}

.spinner {
  border: 3px solid rgba(139, 92, 246, 0.1);
  border-radius: 50%;
  border-top: 3px solid var(--primary-color, #8b5cf6);
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.errorState button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: var(--error-color, #ef4444);
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.errorState button:hover {
  background-color: var(--error-hover, #dc2626);
}

.emptyIcon {
  font-size: 3rem;
  color: var(--text-muted, #6b7280);
  margin-bottom: 1rem;
  opacity: 0.5;
}

.notificationsList {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.notificationItem {
  padding: 1rem;
  background-color: var(--background-light, #f9fafb);
  border: 1px solid var(--border-color, #e5e7eb);
  border-radius: 12px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.notificationItem:hover {
  background-color: rgba(139, 92, 246, 0.05);
  border-color: var(--primary-color, #8b5cf6);
}

.notificationContent h3 {
  margin: 0 0 0.5rem 0;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--text-color, #374151);
}

.notificationContent p {
  margin: 0 0 0.5rem 0;
  font-size: 0.875rem;
  color: var(--text-secondary, #6b7280);
  line-height: 1.5;
}

.timestamp {
  font-size: 0.75rem;
  color: var(--text-muted, #9ca3af);
}

.unreadIndicator {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--primary-color, #8b5cf6);
}

.read {
  opacity: 0.7;
}

/* Dark mode adjustments */
[data-theme="dark"] .container {
  background-color: var(--card-background-dark, #1a1a20);
}

[data-theme="dark"] .header {
  background: linear-gradient(to right, rgba(139, 92, 246, 0.15), transparent);
  border-color: var(--border-color-dark, #2d2d2d);
}

[data-theme="dark"] .closeButton:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

[data-theme="dark"] .notificationItem {
  background-color: rgba(31, 41, 55, 0.5);
  border-color: var(--border-color-dark, #2d2d2d);
}

[data-theme="dark"] .notificationItem:hover {
  background-color: rgba(139, 92, 246, 0.1);
  border-color: var(--primary-color, #8b5cf6);
}

@media (max-width: 640px) {
  .container {
    max-width: 100%;
  }
} 