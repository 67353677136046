@import url('https://fonts.googleapis.com/css2?family=Syne:wght@700&display=swap');

:root {
  --header-height: 70px;
  --primary-gradient: linear-gradient(135deg, #8b5cf6 0%, #6366f1 100%);
  --hover-gradient: linear-gradient(135deg, #7c3aed 0%, #4f46e5 100%);
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--header-height);
  background-color: var(--background-color, white);
  border-bottom: 1px solid var(--border-color, #e5e7eb);
  z-index: 1000;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

[data-theme="dark"] .header {
  background-color: var(--background-color, #1a1a20);
  border-bottom: 1px solid var(--border-color, #2d2d2d);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.headerContainer {
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Logo */
.logo {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  text-decoration: none;
  font-family: 'Syne', sans-serif;
}

.logo img {
  height: 32px;
  width: auto;
  transition: transform 0.3s ease;
}

.logo span {
  font-size: 1.5rem;
  font-weight: 700;
  background: var(--primary-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}

.logo:hover img {
  transform: scale(1.1);
}

.logo:hover span {
  letter-spacing: 2px;
}

/* Navegación */
.nav {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin: 0 2rem;
}

.nav a {
  color: var(--text-color, #4b5563);
  text-decoration: none;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  transition: all 0.3s ease;
  position: relative;
}

[data-theme="dark"] .nav a {
  color: var(--text-color, #f2f2f2);
}

.nav a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: var(--primary-gradient);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.nav a:hover {
  color: var(--primary-color, #8b5cf6);
}

.nav a:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

/* Acciones de Usuario */
.userActions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* Balance */
.balance {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: linear-gradient(135deg, #8b5cf6 0%, #6d28d9 100%);
  border: none;
  border-radius: 20px;
  color: white;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  box-shadow: 0 2px 4px rgba(139, 92, 246, 0.2);
}

.balance:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(139, 92, 246, 0.3);
  background: linear-gradient(135deg, #9f75ff 0%, #7c3aed 100%);
}

.balance svg {
  font-size: 1.2em;
  color: rgba(255, 255, 255, 0.9);
}

.balance .plusIcon {
  font-size: 0.8em;
  margin-left: 4px;
  background: rgba(255, 255, 255, 0.2);
  padding: 4px;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.balance:hover .plusIcon {
  background: rgba(255, 255, 255, 0.3);
  transform: rotate(90deg);
}

.balance span {
  font-size: 0.95em;
  white-space: nowrap;
  letter-spacing: 0.5px;
}

/* Tema claro */
:root[data-theme="light"] .balance {
  background: linear-gradient(135deg, #8b5cf6 0%, #6d28d9 100%);
  color: white;
}

:root[data-theme="light"] .balance:hover {
  background: linear-gradient(135deg, #9f75ff 0%, #7c3aed 100%);
}

/* Animación al cambiar de moneda */
.balance span {
  transition: transform 0.3s ease;
}

.balance:active span {
  transform: scale(0.95);
}

/* Tooltip */
.balance::after {
  content: "Añadir fondos";
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 0.8em;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  white-space: nowrap;
  z-index: 1000;
}

.balance:hover::after {
  opacity: 1;
  visibility: visible;
  bottom: -25px;
}

/* Iconos de notificaciones */
.likeIcon {
  color: #ff1493;
  background: rgba(255, 20, 147, 0.1);
}

.commentIcon {
  color: #4a9eff;
  background: rgba(74, 158, 255, 0.1);
}

.followIcon {
  color: #6b46c1;
  background: rgba(107, 70, 193, 0.1);
}

.serviceIcon {
  color: #34a853;
  background: rgba(52, 168, 83, 0.1);
}

.acceptedIcon {
  color: #34a853;
  background: rgba(52, 168, 83, 0.1);
}

.rejectedIcon {
  color: #e53e3e;
  background: rgba(229, 62, 62, 0.1);
}

.defaultIcon {
  color: #8b5cf6;
  background: rgba(139, 92, 246, 0.1);
}

[data-theme="dark"] .likeIcon {
  background: rgba(255, 20, 147, 0.15);
}

[data-theme="dark"] .commentIcon {
  background: rgba(74, 158, 255, 0.15);
}

[data-theme="dark"] .followIcon {
  background: rgba(107, 70, 193, 0.15);
}

[data-theme="dark"] .serviceIcon,
[data-theme="dark"] .acceptedIcon {
  background: rgba(52, 168, 83, 0.15);
}

[data-theme="dark"] .rejectedIcon {
  background: rgba(229, 62, 62, 0.15);
}

[data-theme="dark"] .defaultIcon {
  background: rgba(139, 92, 246, 0.15);
}

/* Notificaciones */
.notificationsContainer {
  position: relative;
  z-index: 1000;
}

.iconButton {
  background: none;
  border: none;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  color: var(--text-color-light, #6b7280);
  font-size: 1.2rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  width: 42px;
  height: 42px;
}

[data-theme="dark"] .iconButton {
  color: var(--text-color, #f2f2f2);
}

.iconButton:hover {
  background-color: rgba(139, 92, 246, 0.1);
  color: var(--primary-color, #8b5cf6);
  transform: translateY(-1px);
}

.notificationBadge {
  position: absolute;
  top: 0;
  right: 0;
  background: #ef4444;
  color: white;
  font-size: 0.75rem;
  font-weight: 600;
  min-width: 18px;
  height: 18px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  border: 2px solid var(--background-color, white);
  transform: translate(25%, -25%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.notificationsDropdown {
  position: absolute;
  top: calc(100% + 0.75rem);
  right: -0.5rem;
  width: 400px;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.08);
  animation: slideIn 0.2s ease;
}

.notificationsHeader {
  padding: 1rem 1.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notificationsHeader h3 {
  margin: 0;
  font-size: 1.125rem;
  font-weight: 600;
  color: #1a1a1a;
}

.clearAllButton {
  background: none;
  border: none;
  color: #666;
  padding: 0.25rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.clearAllButton:hover {
  color: #e53e3e;
  background-color: rgba(229, 62, 62, 0.08);
}

.deleteButton {
  opacity: 0;
  background: none;
  border: none;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-left: 0.5rem;
  font-size: 0.75rem;
}

.notificationItem:hover .deleteButton {
  opacity: 1;
}

.deleteButton:hover {
  background-color: rgba(229, 62, 62, 0.08);
  color: #e53e3e;
}

[data-theme="dark"] .clearAllButton,
[data-theme="dark"] .deleteButton {
  color: #999;
}

[data-theme="dark"] .clearAllButton:hover,
[data-theme="dark"] .deleteButton:hover {
  color: #f56565;
  background-color: rgba(245, 101, 101, 0.1);
}

.notificationsList {
  max-height: 480px;
  overflow-y: auto;
  padding: 0.75rem;
}

.notificationItem {
  display: flex;
  align-items: flex-start;
  padding: 1rem;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-bottom: 0.5rem;
  background: white;
  position: relative;
}

.notificationItem:hover {
  background: #f8f9ff;
}

/* Estilo para notificaciones leídas */
.notificationItem.read {
  opacity: 0.75;
}

/* Estilo para notificaciones en estado de carga */
.notificationItem.loading {
  background: #f8f9ff;
  border-left: 3px solid #8b5cf6;
}

/* Indicador de carga */
.loadingIndicator {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(139, 92, 246, 0.3);
  border-radius: 50%;
  border-top-color: #8b5cf6;
  animation: spin 0.8s linear infinite;
  margin-left: 0.5rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

[data-theme="dark"] .notificationItem.loading {
  background: #242424;
  border-left: 3px solid #8b5cf6;
}

[data-theme="dark"] .loadingIndicator {
  border: 2px solid rgba(139, 92, 246, 0.2);
  border-top-color: #8b5cf6;
}

.notificationIcon {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f4f1ff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;
  margin-right: 0.875rem;
}

.notificationContent {
  flex: 1;
  min-width: 0;
  padding-top: 0.125rem;
}

.notificationMessage {
  margin: 0;
  font-size: 0.9375rem;
  line-height: 1.4;
  color: #1a1a1a;
  font-weight: 400;
}

.notificationTime {
  font-size: 0.8125rem;
  color: #666;
  margin-top: 0.375rem;
  display: block;
}

.noNotifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  text-align: center;
  color: #666;
}

.noNotifications .emptyIcon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  opacity: 0.5;
}

.noNotifications p {
  margin: 0;
  font-size: 0.875rem;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Menú de Usuario */
.userMenu {
  position: relative;
}

.userButton {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.userButton:hover {
  background-color: rgba(139, 92, 246, 0.1);
}

.userButtonName {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--text-color, #1f2937);
  display: none;
}

@media (min-width: 768px) {
  .userButtonName {
    display: block;
  }
}

.userAvatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: all 0.3s ease;
}

.userButton:hover .userAvatar {
  border-color: #8b5cf6;
  transform: scale(1.1);
}

.userDropdown {
  position: absolute;
  top: calc(100% + 0.5rem);
  right: 0;
  width: 280px;
  background-color: var(--background-color, white);
  border: 1px solid var(--border-color, #e5e7eb);
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

[data-theme="dark"] .userDropdown {
  background-color: var(--background-color, #1a1a20);
  border-color: var(--border-color, #2d2d2d);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.3);
}

.userInfo {
  padding: 1rem;
  border-bottom: 1px solid var(--border-color, #e5e7eb);
  display: flex;
  align-items: center;
  gap: 1rem;
}

.userAvatarLarge {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid #8b5cf6;
}

.userName {
  margin: 0;
  font-weight: 600;
  color: var(--text-color, #1f2937);
}

[data-theme="dark"] .userName {
  color: #f5f5f5;
}

.userEmail {
  color: #6b7280;
  font-size: 0.875rem;
  margin: 0.25rem 0 0;
}

.dropdownLinks {
  padding: 0.5rem;
}

.dropdownLinks a,
.dropdownLinks button {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  padding: 0.75rem 1rem;
  color: var(--text-color, #4b5563);
  text-decoration: none;
  background: none;
  border: none;
  font-size: 0.875rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

[data-theme="dark"] .dropdownLinks a,
[data-theme="dark"] .dropdownLinks button {
  color: #f5f5f5;
}

.dropdownLinks a:hover,
.dropdownLinks button:hover {
  background: rgba(139, 92, 246, 0.1);
  color: #8b5cf6;
  transform: translateX(5px);
}

/* Botón de Login */
.loginButton {
  background: var(--primary-gradient);
  color: white;
  padding: 0.5rem 1.25rem;
  border-radius: 8px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
}

.loginButton:hover {
  background: var(--hover-gradient);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(139, 92, 246, 0.3);
}

/* Botón de menú móvil */
.menuButton {
  display: none;
  background: none;
  border: none;
  color: var(--text-color, #4b5563);
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  transition: color 0.2s ease;
  z-index: 1001;
}

.menuButton:hover {
  color: var(--primary-color, #8b5cf6);
}

[data-theme="dark"] .menuButton {
  color: var(--text-color, #f2f2f2);
}

/* Media Queries */
@media (max-width: 1024px) {
  .headerContainer {
    padding: 0 1rem;
  }

  .nav {
    gap: 1rem;
  }

  .balance {
    padding: 0.5rem 0.75rem;
  }

  .userButtonName {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media (max-width: 768px) {
  .headerContainer {
    padding: 0 1rem;
  }

  .menuButton {
    display: block;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--background-color-light, #f9fafb);
  }

  .menuButton:active {
    transform: scale(0.95);
  }

  .nav {
    position: fixed;
    top: var(--header-height);
    left: 0;
    right: 0;
    margin: 0;
    background-color: white;
    padding: 1rem;
    flex-direction: column;
    gap: 1rem;
    border-bottom: 1px solid #e5e7eb;
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: 999;
  }

  .navActive {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }

  .userActions {
    gap: 12px;
    margin-right: 10px;
  }

  .notificationsDropdown,
  .userDropdown {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0;
    border-radius: 12px 12px 0 0;
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.15);
    animation: slideUp 0.3s ease;
  }

  @keyframes slideUp {
    from { transform: translateY(100%); }
    to { transform: translateY(0); }
  }

  .balance {
    padding: 0.5rem 0.75rem;
  }
  
  .iconButton {
    width: 40px;
    height: 40px;
  }
  
  .userAvatar {
    width: 34px;
    height: 34px;
  }

  /* Mejoras en el diseño de notificaciones y perfil para móviles */
  .notificationsHeader {
    position: sticky;
    top: 0;
    background: inherit;
    z-index: 10;
    padding: 16px;
    border-bottom: 1px solid var(--border-color);
  }

  .notificationsList {
    padding: 0 12px 12px;
  }
  
  /* Hacer que el menú de usuario sea más ancho en móviles */
  .userDropdown {
    width: 100%;
    max-width: 100%;
    border-radius: 12px 12px 0 0;
  }
}

@media (max-width: 480px) {
  .header {
    height: 60px; /* Reducir altura del header en móviles */
  }
  
  .headerContainer {
    padding: 0 8px;
  }
  
  .logo span {
    display: none;
  }

  .balance {
    padding: 8px;
    min-width: auto;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    justify-content: center;
  }

  .balance span {
    display: none;
  }

  .balance .plusIcon {
    margin-left: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 16px;
    height: 16px;
    padding: 2px;
  }

  .userActions {
    gap: 8px;
  }
  
  .iconButton {
    width: 40px;
    height: 40px;
  }

  .notificationsDropdown,
  .userDropdown {
    max-height: 90vh;
  }
  
  .userAvatar {
    width: 32px;
    height: 32px;
  }
  
  .userButton {
    padding: 4px;
  }
  
  .userButtonName {
    display: none;
  }
}

/* Para pantallas muy pequeñas */
@media (max-width: 360px) {
  .userActions {
    gap: 6px;
  }
  
  .balance {
    width: 36px;
    height: 36px;
  }
  
  .iconButton {
    width: 36px;
    height: 36px;
  }
  
  .userButton {
    padding: 3px;
  }
  
  .userAvatar {
    width: 30px;
    height: 30px;
  }
}

[data-theme="dark"] .notificationsDropdown {
  background: #1a1a1a;
  border-color: rgba(255, 255, 255, 0.08);
}

[data-theme="dark"] .notificationsHeader {
  background: #1a1a1a;
  border-color: rgba(255, 255, 255, 0.08);
}

[data-theme="dark"] .notificationItem {
  background: #1a1a1a;
}

[data-theme="dark"] .notificationItem:hover {
  background: #242424;
}

[data-theme="dark"] .notificationIcon {
  background: rgba(255, 255, 255, 0.06);
}

[data-theme="dark"] .notificationMessage {
  color: #f5f5f5;
}

[data-theme="dark"] .notificationTime {
  color: #999;
}

[data-theme="dark"] .notificationsHeader h3 {
  color: #f5f5f5;
}

[data-theme="dark"] .noNotifications {
  color: #999;
}

/* Divisor para el menú desplegable */
.dropdownDivider {
  height: 1px;
  background-color: var(--border-color, #e5e7eb);
  margin: 8px 0;
}

[data-theme="dark"] .dropdownDivider {
  background-color: var(--border-color, #2d2d2d);
}

/* Enlaces de navegación móvil */
.mobileNavLink {
  font-weight: 500 !important;
  color: var(--primary-color, #8b5cf6) !important;
}

.mobileNavLink svg {
  color: var(--primary-color, #8b5cf6);
}

/* Estilo para el enlace de membresía */
.membershipLink {
  position: relative;
}

.membershipLink::after {
  content: 'Premium';
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.7em;
  padding: 2px 6px;
  background: linear-gradient(135deg, #f6e05e 0%, #ed8936 100%);
  color: #000;
  border-radius: 4px;
  font-weight: 700;
}

/* Media queries adicionales */
@media (max-width: 768px) {
  /* Hacer que el menú de usuario sea más ancho en móviles */
  .userDropdown {
    width: 100%;
    max-width: 100%;
    border-radius: 12px 12px 0 0;
  }
} 