/* =========================================
   Variables.css - Sistema de diseño unificado
========================================= */

/* Sistema de Espaciado */
:root {
  --space-1: clamp(0.25rem, 0.5vw, 0.5rem);
  --space-2: clamp(0.5rem, 1vw, 0.75rem);
  --space-3: clamp(0.75rem, 1.5vw, 1rem);
  --space-4: clamp(1rem, 2vw, 1.5rem);
  --space-5: clamp(1.5rem, 3vw, 2rem);
  --space-6: clamp(2rem, 4vw, 3rem);
  
  /* Alias para compatibilidad */
  --spacing-xs: var(--space-1);
  --spacing-sm: var(--space-2);
  --spacing-md: var(--space-3);
  --spacing-lg: var(--space-4);
  --spacing-xl: var(--space-5);
  --spacing-xxl: var(--space-6);
}

/* Sistema Tipográfico */
:root {
  --text-xs: clamp(0.75rem, 1vw, 0.875rem);
  --text-sm: clamp(0.875rem, 1.2vw, 1rem);
  --text-base: clamp(1rem, 1.5vw, 1.125rem);
  --text-lg: clamp(1.125rem, 2vw, 1.25rem);
  --text-xl: clamp(1.25rem, 2.5vw, 1.5rem);
  --text-2xl: clamp(1.5rem, 3vw, 1.875rem);
  --text-3xl: clamp(1.875rem, 4vw, 2.25rem);
  --text-4xl: clamp(2.25rem, 5vw, 3rem);
}

/* Breakpoints */
:root {
  --screen-xs: 480px;
  --screen-sm: 640px;
  --screen-md: 768px;
  --screen-lg: 1024px;
  --screen-xl: 1280px;
  --screen-2xl: 1536px;
}

/* Sistema de Z-index - Reorganizado y Optimizado */
:root {
  --z-negative: -1;
  --z-base: 1; 
  --z-elevate: 2;
  
  /* Niveles de componentes UI básicos */
  --z-content: 10;
  --z-dropdown: 20;
  --z-sticky: 50;
  --z-header: 100;
  --z-sidebar: 150;
  
  /* Diálogos y modales */
  --z-drawer: 200;
  --z-dialog: 300;
  --z-modal-backdrop: 900;
  --z-modal: 1000;
  
  /* Elementos flotantes de alta prioridad */
  --z-popover: 1100;
  --z-tooltip: 1200;
  --z-toast: 1300;
  
  /* Galerías y visualizadores - Valores elevados para asegurar siempre la visibilidad */
  --z-gallery-modal: 9000;
  --z-gallery-content: 9001;
  
  /* Elementos de máxima prioridad */
  --z-critical: 10000;
  
  /* Alias específicos para componentes */
  --z-perfil-avatar: 13;
  --z-perfil-tabs: 15;
  --z-perfil-content: 5;
  --z-modal-overlay: var(--z-modal-backdrop);
  --z-modal-content: var(--z-modal);
  --z-gallery-form-overlay: var(--z-gallery-modal);
  --z-gallery-form-content: var(--z-gallery-content);
}

/* Clase específica para cuando hay un modal abierto */
.modal-open {
  --z-gallery-form-overlay: 9000 !important;
  --z-gallery-form-content: 9001 !important;
}

/* Sistema de Transiciones */
:root {
  --transition-speed: 0.3s;
  --transition-speed-fast: 0.15s;
  --transition-speed-slow: 0.5s;
  --transition-timing: cubic-bezier(0.4, 0, 0.2, 1);
  
  /* Alias para compatibilidad */
  --transition-fast: var(--transition-speed-fast) ease;
  --transition-normal: var(--transition-speed) ease;
  --transition-slow: var(--transition-speed-slow) ease;
}

/* Sistema de Layout */
:root {
  --container-width: min(95%, 1400px);
  --container-padding: clamp(1rem, 3vw, 2rem);
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 12px;
  --border-radius-xl: 16px;
  --border-radius-xxl: 24px;
  --border-radius-round: 50%;
  
  /* Alias para compatibilidad */
  --border-radius: var(--border-radius-md);
  --border-width: 1px;
  --border-width-thick: 2px;
}

/* Sistema de Header */
:root {
  --header-height: 70px;
  --header-offset: calc(var(--header-height) + 20px);
  --header-padding: 0 1.5rem;
  --header-title-size: 1.25rem;
  --header-icon-size: 24px;
  --header-button-padding: 0.3rem 0.6rem;
  --header-profile-pic-size: 28px;
}

/* Sistema de Inputs */
:root {
  --input-padding: 12px 16px;
  --input-height: 42px;
  --input-font-size: var(--text-base);
  --input-shadow: none;
}

/* Sistema de Checkbox */
:root {
  --checkbox-size: 18px;
  --checkbox-border-radius: 4px;
  --checkbox-border-width: 2px;
}

/* Definiciones para Transparencia (sin colores) */
:root {
  /* Configuración de transparencia (valores por defecto) */
  --component-opacity: 1;
  --element-opacity: 1;
  --bg-blur: 0px;

  /* Variables para control desde CustomizationContext */
  --app-transparency-value: 1;
  --app-element-opacity: 1;
  --app-blur-value: 0px;
  --app-overlay-opacity: 0.5;

  /* Variable de imagen de fondo */
  --background-image: none;
  
  /* Valores RGB Base */
  --white-rgb: 255, 255, 255;
  --black-rgb: 0, 0, 0;
  
  /* Valores RGB para modo claro y oscuro */
  --light-bg-rgb: 255, 255, 255;
  --light-element-rgb: 248, 249, 250;
  --dark-bg-rgb: 26, 26, 32;
  --dark-element-rgb: 47, 47, 56;
  --current-bg-rgb: var(--light-bg-rgb);
  --current-element-rgb: var(--light-element-rgb);
}

/* Colores principales - MODO CLARO */
:root {
  /* Colores primarios unificados */
  --primary-color: #9333EA; /* Color principal de la marca */
  --primary-hover: #7928ca;
  --primary-light: rgba(147, 51, 234, 0.1);
  --primary-dark: #7928ca;
  
  /* Alias para compatibilidad */
  --accent-color: var(--primary-color);
  --accent-hover: var(--primary-hover);
  --accent-light: var(--primary-light);
  --accent-dark: var(--primary-dark);
  
  /* Colores secundarios */
  --secondary-color: #4a5568;
  
  /* Valores RGB para uso con opacidad */
  --primary-rgb: 147, 51, 234;
  
  /* Colores base - Modo Claro */
  --background-color: #FFFFFF;
  --background-color-secondary: #F9F9FB;
  --background-elevated: #F8F9FA;
  --background-hover: rgba(0, 0, 0, 0.03);
  --card-background: #FFFFFF;
  
  /* Colores de texto - Modo Claro */
  --text-color: #2D2D2D;
  --text-secondary: #6B7280;
  --text-tertiary: #999999;
  --text-on-dark: #FFFFFF;
  
  /* Valores RGB de texto */
  --text-primary-rgb: 45, 45, 45;
  --text-secondary-rgb: 107, 114, 128;
  
  /* Colores de borde - Modo Claro */
  --border-color: #E5E7EB;
  
  /* Colores de notificación */
  --success-color: #10B981;
  --error-color: #EF4444;
  --warning-color: #F59E0B;
  --info-color: #3B82F6;
  
  /* Valores RGB de notificación */
  --success-rgb: 16, 185, 129;
  --error-rgb: 239, 68, 68;
  
  /* Link colors */
  --link-color: var(--primary-color);
  --link-hover-color: var(--primary-hover);
  
  /* Card styles */
  --card-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  --hover-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1);
  
  /* Transparencia y superposición */
  --overlay-color: rgba(0, 0, 0, 0.3);
  
  /* Variables de Personalización */
  --custom-primary-color: var(--primary-color);
  --custom-secondary-color: var(--secondary-color);
  --custom-accent-color: var(--primary-color);
  --custom-text-color: var(--text-color);
  --custom-card-background: var(--card-background);
  --custom-button-color: var(--primary-color);
  --custom-background-color: var(--background-color);
  --custom-background-color-rgb: var(--white-rgb);
}

/* Tema oscuro - Implementación completa */
:root[data-theme="dark"] {
  /* Colores base - Modo Oscuro */
  --background-color: #1a1a20;
  --background-color-secondary: #24242d;
  --background-elevated: #2f2f38;
  --background-hover: rgba(47, 47, 56, 0.5);
  --card-background: #2f2f38;
  
  /* Colores de texto - Modo Oscuro */
  --text-color: #f2f2f2;
  --text-secondary: rgba(242, 242, 242, 0.7);
  --text-tertiary: rgba(242, 242, 242, 0.5);
  --text-on-dark: #FFFFFF;
  
  /* Valores RGB de texto - Modo Oscuro */
  --text-primary-rgb: 242, 242, 242;
  --text-secondary-rgb: 156, 163, 175;
  
  /* Colores de borde - Modo Oscuro */
  --border-color: #2d2d2d;
  
  /* Sombras - Modo Oscuro */
  --card-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  --hover-shadow: 0 6px 16px rgba(0, 0, 0, 0.6);
  
  /* Colores primarios - Modo Oscuro */
  --primary-color: #8b5cf6;
  --primary-hover: #7c3aed;
  --primary-light: rgba(139, 92, 246, 0.2);
  --primary-dark: #7c3aed;
  
  /* Alias para compatibilidad - Modo Oscuro */
  --accent-color: var(--primary-color);
  --accent-hover: var(--primary-hover);
  --accent-light: var(--primary-light);
  --accent-dark: var(--primary-dark);
  
  /* Variables específicas para inputs - Modo Oscuro */
  --input-bg: #24242d;
  --input-border: #2d2d2d;
  --input-placeholder-color: rgba(255, 255, 255, 0.4);
  --input-disabled-bg: rgba(255, 255, 255, 0.05);
  --input-disabled-color: rgba(255, 255, 255, 0.3);
  
  /* Variables específicas para checkbox - Modo Oscuro */
  --checkbox-bg: var(--background-color);
  --checkbox-border: var(--border-color);
  --checkbox-disabled-bg: rgba(255, 255, 255, 0.05);
  --checkbox-disabled-border: rgba(255, 255, 255, 0.3);
  
  /* Variables específicas para modal - Modo Oscuro */
  --modal-bg: #2f2f38;
  --modal-overlay-bg: rgba(0, 0, 0, 0.85);
  --modal-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
  
  /* Variables de personalización - Modo Oscuro */
  --custom-background-color: var(--background-color);
  --custom-background-color-rgb: 26, 26, 32;
  --custom-primary-color: var(--primary-color);
  --custom-secondary-color: var(--secondary-color);
  --custom-accent-color: var(--accent-color);
  --custom-text-color: var(--text-color);
  --custom-card-background: var(--card-background);
  --custom-button-color: var(--primary-color);
}

/* Media Queries para preferencias de movimiento */
@media (prefers-reduced-motion: reduce) {
  :root {
    --transition-speed: 0s;
    --transition-speed-fast: 0s;
    --transition-speed-slow: 0s;
  }
}

/* Fuentes y texto */
:root {
  --font-primary: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --font-secondary: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  --font-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  
  --line-height-none: 1;
  --line-height-tight: 1.25;
  --line-height-normal: 1.5;
  --line-height-relaxed: 1.75;
  --line-height-loose: 2;
  
  --font-weight-thin: 100;
  --font-weight-extralight: 200;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-extrabold: 800;
  --font-weight-black: 900;
}

/* Efectos y filtros */
:root {
  --backdrop-blur: 8px;
  --backdrop-saturate: 180%;
  --backdrop-filter-default: blur(var(--backdrop-blur)) saturate(var(--backdrop-saturate));
  
  --shadow-xs: 0 1px 2px rgba(0, 0, 0, 0.05);
  --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --shadow-2xl: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  --shadow-inner: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  
  --shadow-focus-primary: 0 0 0 3px rgba(var(--primary-rgb), 0.5);
  --shadow-focus-error: 0 0 0 3px rgba(var(--error-rgb), 0.5);
  --shadow-focus-success: 0 0 0 3px rgba(var(--success-rgb), 0.5);
}

/* Media Queries para ajustar variables en móvil */
@media (max-width: 768px) {
  :root {
    --container-padding: var(--space-3);
    --header-height: 60px;
    --header-offset: calc(var(--header-height) + 60px);
  }
}